<template>
    <section>
        <ValidationObserver ref="validacion">
            <div class="row mx-0 px-3 mb-3">
                <div class="col bg-fondo br-8 text-general py-2">
                    <p class="f-12">Valor del inventario</p>
                    <p class="text-general f-22 f-600">
                        {{ separadorNumeroDecimales(infoInventario.valor_inventario) }}
                    </p>
                </div>
                <div class="col-auto bg-fondo br-8 text-general py-2 ml-3">
                    <p class="f-12">Productos</p>
                    <p class="text-general f-22 f-600">
                        {{ infoInventario.total_productos }}
                    </p>
                </div>
            </div>
            <template v-if="verInfoRotacion && infoRotacion.mostrar_card">
                <div class="row mx-3 align-items-center br-12 p-2 mt-3 bg-green text-white">
                    <i class="icon-up f-28 pl-3" />
                    <div class="col-auto pl-0 f-30 text-white f-500">
                        {{ formatoPorcentaje(infoRotacion.ganancia_predeterminada) }}%
                    </div>
                    <div class="col px-0">
                        Ganancia sobre <br /> los producto
                    </div>
                    <i class="icon-pencil-outline f-20 cr-pointer" @click="editarValor" />
                </div>
                <div class="row mx-3 align-items-center br-12 p-2 mt-3 text-white cr-pointer" :class="bannerModificados ? 'border-red' : ''" @click="getModificados">
                    <i class="icon-alert-circled f-20 text-alert" />
                    <p class="col text-gris2 f-15 px-2">
                        El valor de <span class="f-600">{{ infoRotacion.total_productos_modificados }} Productos</span>  ha sido modificado individualmente
                    </p>
                    <i class="icon-angle-right text-gris2 f-22" />
                </div>
            </template>
            <!-- Partials -->
            <modal ref="modalEditar" titulo="Editar ganancia sobre los productos" no-aceptar adicional="Guardar" @adicional="postPorcentajeGanancia">
                <div class="row mx-0 justify-center">
                    <cargando v-if="cargando" />
                    <div class="col-8">
                        <p class="text-general f-12 pl-3">
                            Porcentaje de ganancia
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required" name="Porcentaje">
                            <el-input-number v-model="porcentaje" class="w-100 soloNumeros" :precision="1" :step="0.1" :max="99.9" :controls="false" placeholder="%" @focus="soloNumeros" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </modal>
        </ValidationObserver>
    </section>
</template>

<script>
import Service from "~/services/tienda/ganancias"

export default {
    props: {
        verInfoRotacion : {
            type: Boolean,
            default: false
        },
        infoInventario: {
            type: Object,
            default: () => {}
        },
        infoRotacion: {
            type: Object,
            default: () => {}
        },
    },
    data(){
        return {
            cargando: false,
            valorAlto: false,
            tituloModal: '',
            porcentaje: '',
            bannerModificados:false
        }
    },
    computed:{
        id_tienda(){
            return this.$route.params.id_tienda
        },
    },
    methods: {
        async postPorcentajeGanancia(){
            try {
                let id_tienda = null;
                if(this.id_tienda != undefined && this.id_tienda != null){
                    id_tienda = this.id_tienda;
                }
                this.cargando = true;
                const form = {
                    id_tienda: id_tienda,
                    valor: this.porcentaje
                };
                const {data} = await Service.postPorcentajeGanancia(form)
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.infoRotacion.ganancia_predeterminada = data.porcentaje;
                this.limpiar();
                this.$refs.modalEditar.toggle();
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },
        soloNumeros(){
            $('.soloNumeros').keypress(function(event){
                // Bloqueo de letras y caracteres especiales
                let char = String.fromCharCode(event.keyCode);
                let pattern = '[a-zA-Z|°!"#$%&/()=?¿¡]';
                if (char.match(pattern)){
                    return false;
                }
            });
        },
        limpiar(){
            this.porcentaje = '';
            this.$refs.validacion.reset();
        },
        editarValor(){
            if(this.verInfoRotacion != false && this.infoRotacion.mostrar_card != false){
                this.porcentaje = this.infoRotacion.ganancia_predeterminada;
                this.$refs.modalEditar.toggle();
            }
        },
        formatoPorcentaje(value){
            let porcentaje = value.split('.');
            if(porcentaje.length > 1){
                let value1 = porcentaje[0];
                let value2 = porcentaje[1];
                if(parseInt(value2) > 0){
                    return value;
                }
                return value1;
            }
            return value;
        },
        getModificados(){
            this.bannerModificados = !this.bannerModificados
            this.$emit('accion')
        }
    }
}
</script>
<style lang="scss" scoped>
.border-red{
    border-radius: 12px;
    border: 2px solid #FF1720;
}
</style>
